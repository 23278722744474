<template>
	<transition name="sligeFromUp">
		<div class="fullPage" v-if="openedMenu" v-scroll-lock="openedMenu" :class="{opened:openedMenu}">
			<VuePerfectScrollbar class="fullPage__body" :settings="scrollSettings" @ps-scroll-y="scrollHandle">
				<div class="menu container">
					<ul class="menuBlock wow fadeInUp" v-for="(block,index) in menuItems" v-if="block" :data-wow-delay="index * 0.1 + 's'">
						<li v-for="(link,index2) in block" class="wow fadeInUp" :data-wow-delay="index2 * 0.2 + 's'">
							<router-link :to="link.link" v-html="link.name"></router-link>
							<ul v-if="link.childrens">
								<li v-for="(children, index3) in link.childrens" class="wow fadeInUp" :data-wow-delay="index3 * 0.2 + 's'">
									<router-link :to="children.link" v-html="children.name"></router-link>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div class="menuFooter">
					<div class="menuFooter__container container">
						<div class="soc wow fadeInUp" data-wow-delay="1s">
							<a href="https://www.instagram.com/artrockets.ru/" target="_blank"><span class="icon-inst"></span></a>
							<a href="https://vk.com/artrockets" target="_blank"><span class="icon-vk"></span></a>
						</div>
						<a href="/presentaton.pdf" target="_blank" class="file wow fadeInUp" data-wow-delay="1s">Наша презентация</a>
					</div>
				</div>
			</VuePerfectScrollbar>
		</div>
	</transition>
</template>



<style lang="sass">
	@import '@/assets/sass/Menu'
</style>

<script>
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'
	import Header from '@/components/app/Header'
	
	import menuLinks from "@/assets/json/mainMenu.json";

	export default {
		props: ['openedMenu'],
		data: () => ({
			image: require(`@/assets/img/banner/1.png`),
			animatedImageClass: 'neonLogo',
			menuLinks: menuLinks,
			scrollSettings: {
				suppressScrollX: true
			},
			lastWidth: 0,
			menuItems: []
		}),
		methods:{
			openMenuFunc: function(){
				this.$emit('openMenuFunc');
			},
			resize: function(){
				var comp = this;
				var menu = [];

				if(window.innerWidth > 1100 && (comp.lastWidth <= 1100 || !menu.length)){
					comp.menuLinks.forEach(function(element, index) {
						var item = [];
						item["name"] = element.name;
						item["link"] = element.link;
						if(element.childrens){
							item["childrens"] = element.childrens;
						}
						if(!menu[element.column]){
							menu[element.column] = [];
						}
						menu[element.column].push(item);
					});
					comp.menuItems = menu;
				} else if(window.innerWidth > 600 && window.innerWidth <= 1100 && (comp.lastWidth <= 600 || comp.lastWidth > 1100 || !menu.length)){
					comp.menuLinks.forEach(function(element, index) {
						var item = [];
						item["name"] = element.name;
						item["link"] = element.link;
						if(element.childrens){
							item["childrens"] = element.childrens;
						}

						if(!menu[element.tabletColumn]){
							menu[element.tabletColumn] = [];
						}
						menu[element.tabletColumn].push(item);
					});
					comp.menuItems = menu;
				} else if(window.innerWidth <= 600 && (comp.lastWidth > 600 || !menu.length)){
					comp.menuLinks.forEach(function(element, index) {
						var item = [];
						item["name"] = element.name;
						item["link"] = element.link;
						if(element.childrens){
							item["childrens"] = element.childrens;
						}
						if(!menu[0]){
							menu[0] = [];
						}
						menu[0].push(item);
					});
					comp.menuItems = menu;
				}
				
				comp.lastWidth = window.innerWidth;
			},
			scrollHandle(evt) {
				evt.srcElement.getElementsByClassName("wow").forEach(function(el, index){
					el.classList.add("wow");
					el.classList.add("fadeInUp");
					el.classList.add("animated");
					el.style.visibility = "visible";
					el.style.animationName = "fadeInUp";
				});
			}
		},
		watch:{
			'openedMenu': function(){
				var comp = this;
				if(this.openedMenu){
					
				}
			}
		},
		mounted(){
			var comp = this;

			this.lastWidth = window.innerWidth;
			comp.resize();

			window.addEventListener('resize', function(event){
				comp.resize();
			});

			var wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				live: true,
				mobile: false
			});
			wow.init();
		},
		components: {
			VuePerfectScrollbar, Header
		}
	}
</script>